import {
  LoginOutlined,
  UserOutlined,
  DownOutlined
} from '@ant-design/icons';
import { Dropdown, Space, notification } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next" // Importation de i18next


const items = [
  {
    label: '🇬🇧',
    key: 'en'
  },
  {
    label: '🇫🇷',
    key: 'fr'
  },
  {
    label: '🇩🇪',
    key: 'de'
  },
  {
    label: '🇪🇦',
    key: 'es'
  },
  {
    label: '🇮🇹',
    key: 'it'
  }
];


    
function LanguageSelector({ navigate, kernel, user }) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const onClick = ({ key }) => {
    setLanguage(key);
    i18n.changeLanguage(key);
  };
  
  return <Dropdown
    overlayClassName='lng_account'
    arrow={false}
    trigger="click"
    menu={{
      items: items?.map(lng => ({
        ...lng,
        label: `${lng.label} ${t(`Language.${lng.key}`)}`,
      })),
      onClick,
    }}
  >
    <a className='ant-menu-title-content' onClick={(e) => e.preventDefault()}>
      <Space>
        <DownOutlined />{items?.find(item => item?.key == language)?.label}
      </Space>
    </a>
  </Dropdown>
}

export default LanguageSelector;