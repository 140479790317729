import {
  LoginOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Dropdown, Space, notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';


const items = [
  {
    label: <Link rel="noopener noreferrer">
            Preferences
        </Link>,
    key: 'account.information',
    icon: <UserOutlined />
  },
  {
    label: 'Logout',
    key: 'disconnect',
    icon: <LoginOutlined />
  }
];


    
function MenuDropDown({ navigate, kernel, user }) {

  const onClick = ({ key }) => {
  const path = key.split(".").join("/")
    
    if (key === "disconnect") {
        notification.success({
            message: `Disconnected`,
            description: "Your session has been closed",
        })
        kernel.session.store("")
        navigate(`/session/login`)
        return
    }

    navigate(`/${path}`)
  };
  
  return <Dropdown
    overlayClassName='menu_account'
    arrow={false}
    trigger="click"
    menu={{
      items,
      onClick,
    }}
  >
    <a style={{right: 120}} className='ant-menu-title-content' onClick={(e) => e.preventDefault()}>
      <Space>
        <UserOutlined />{user?.firstName + ' ' + user?.lastName}
      </Space>
    </a>
  </Dropdown>
}

export default MenuDropDown;